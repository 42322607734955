import React from 'react';
import { Link } from "react-router-dom";

export default function Header(props) {
    return (<>
        <header className="header">
            <div className="container">
                <div className="d-flex align-items-center justify-content-between">
                    <div className="logo">
                        <Link to="/">
                            <img src="/assets/images/logo.svg" alt="" />
                        </Link>
                    </div>
                    <div className="header_right" >
                        <div className="contact_info">
                            <ul>
                                <li className="header_call">
                                    <div className="contact_info_icn">
                                        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M16.95 19C14.8667 19 12.8083 18.5208 10.775 17.5623C8.74167 16.6039 6.89167 15.2447 5.225 13.4847C3.55833 11.7248 2.271 9.77198 1.363 7.62639C0.455 5.4808 0.000666667 3.30811 0 1.10833C0 0.791667 0.0999999 0.527778 0.3 0.316667C0.5 0.105556 0.75 0 1.05 0H5.1C5.33333 0 5.54167 0.0837407 5.725 0.251222C5.90833 0.418704 6.01667 0.616444 6.05 0.844444L6.7 4.53889C6.73333 4.82037 6.725 5.05787 6.675 5.25139C6.625 5.44491 6.53333 5.61204 6.4 5.75278L3.975 8.33889C4.30833 8.98981 4.704 9.61857 5.162 10.2252C5.62 10.8318 6.12433 11.4169 6.675 11.9806C7.19167 12.5259 7.73333 13.0319 8.3 13.4984C8.86667 13.965 9.46667 14.3914 10.1 14.7778L12.45 12.2972C12.6 12.1389 12.796 12.0203 13.038 11.9415C13.28 11.8627 13.5173 11.8405 13.75 11.875L17.2 12.6139C17.4333 12.6843 17.625 12.812 17.775 12.9971C17.925 13.1821 18 13.3887 18 13.6167V17.8917C18 18.2083 17.9 18.4722 17.7 18.6833C17.5 18.8944 17.25 19 16.95 19Z" /></svg>
                                    </div>
                                    <div className="contact_info_cnt">
                                        <span>Call us </span>
                                        <a href="tel:01892722722">01892 722 722</a>
                                    </div>
                                </li>
                                <li>
                                    <div className="contact_info_icn">
                                        <svg width="36" height="29" viewBox="0 0 36 29" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M31.8627 0.114288H3.53596C1.5885 0.114288 0.0128214 1.69395 0.0128214 3.62465L-0.00488281 24.6868C-0.00488281 26.6175 1.5885 28.1972 3.53596 28.1972H31.8627C33.8102 28.1972 35.4036 26.6175 35.4036 24.6868V3.62465C35.4036 1.69395 33.8102 0.114288 31.8627 0.114288ZM31.8627 7.13501L17.6994 15.9109L3.53596 7.13501V3.62465L17.6994 12.4006L31.8627 3.62465V7.13501Z" fill="#1D399B" /> </svg>
                                    </div>
                                    <div className="contact_info_cnt">
                                        <span>Email us</span>
                                        <a href="mailto:info@alphacar.uk">info@alphacar.uk</a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        </>
        );
}