import React, { useEffect, useRef, useState } from 'react';
import { get } from '../helpers/api_helper'

export default function Header(props) {
    const selectRef = useRef(null);

    const [search, setSearch] = useState(props.address);
    const [activeOption, setActiveOption] = useState(-1);
    const [showOptions, setshowOptions] = useState(false);
    const [suggestion, setsuggestion] = useState([]);

    useEffect(() => {
        const listener = document.addEventListener('click', handleClickOutside);
        return () => document.removeEventListener('click', listener);
      }, [selectRef]);

    const onChange = async (e) => {
        var search_value = e.currentTarget.value;
        if(!search_value){
            props.callBack({
                latitude: '',
                longitude: '',
                address: '',
            })
        }

        setSearch(search_value);
        setActiveOption(-1);
        get(`https://api.getAddress.io/autocomplete/${search_value}?api-key=5-cW7JBaeUyiLzhBqqOLOQ43211`).then((res_data) => {

            if (res_data.suggestions) {
                setsuggestion(res_data.suggestions);
            }
        })
        
        setshowOptions(true);
    };

    const onKeyDown = async (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            if (showOptions && activeOption >= 0) {
                // setSearch(suggestion[activeOption].address);
                get_lat_login(suggestion[activeOption])
                setshowOptions(false);
                setActiveOption('');
            } else {
                // alert(search);
            }
        } else if (e.keyCode === 38) {
            if (activeOption === 0) {
                return;
            }
            setActiveOption(parseInt(activeOption) - 1)
        } else if (e.keyCode === 40) {
            if (activeOption === suggestion.length - 1) {
                return;
            }
            setActiveOption(parseInt(activeOption) + 1)
        }
    };
    const select_serch = async (select, e) => {
        get_lat_login(select);
        setshowOptions(false);
        setActiveOption('');
    }
    
    const get_lat_login = async (address) => {
        console.log('data -> ',address);
        setSearch(address.address);
        var data = await get(`https://api.getAddress.io/get/${address.id}?api-key=5-cW7JBaeUyiLzhBqqOLOQ43211`);
        props.callBack({
            latitude: (data.latitude)? data.latitude : '',
            longitude: (data.longitude)? data.longitude : '',
            address: address.address,
        })
    }

    const handleClickOutside = (event) => {
        if (selectRef.current && !selectRef.current.contains(event.target)) {
            setshowOptions(false); // Call the provided function
        }
    };
    return (<>
        <div className='search_box' ref={selectRef}>
            <input type="text" onChange={onChange} onKeyDown={onKeyDown} value={search} className="input form-control text-capitalize" placeholder={props.placeholder} />
            {showOptions && <div className="suggestion">
                <ul className="options">
                    {suggestion.map((optionName, index) => {
                        var className = (index === activeOption) ? 'active text-capitalize' : 'text-capitalize';
                        return (
                            <li className={className} key={index} onClick={select_serch.bind(this, optionName)}>{optionName.address}</li>
                        );
                    })}
                </ul>
            </div>}
        </div>

    </>
    );
}