import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { post } from '../../helpers/api_helper';
import { GET_COUNTRIES_LIST, POST_SIGN_UP } from '../../helpers/url_helper';
import { is_login, setUserSession } from '../../helpers/Helper';
import SimpleReactValidator from 'simple-react-validator';

import useMounted from './../../hooks/useMounted';
import { useNavigate } from 'react-router-dom';

export default function Register(props) {
    const navigate = useNavigate();
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [Countries, SetCountries] = useState([]);
    const [form_inputs, setInputs] = useState({
        userName: '',
        email: '',
        mobileNumber: '',
        countryId: '232',
        houseNumber: '',
        street: '',
        postCode: '',
        city: '',
        password: '',
        c_password: '',
        newsLetter: 'Yes',
        tc: '',
    });

    const simpleValidator = useRef(new SimpleReactValidator());
    const [, forceUpdate] = useState();

    useMounted(() => {
        if(is_login()){
            navigate('/')
        }
        getCountries();
    }, [props])

    //UpdateStatus
    const getCountries = async () => {
        var res_data = await post(GET_COUNTRIES_LIST, {});
        if (res_data.status) {
            SetCountries(res_data.data.countries_list);
        } else {
            toast.error(res_data.message);
            navigate('/');
        }
    }

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        if (event.target.name == 'tc') {
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: (event.target.checked) ? 1 : '' }));
        } else if (event.target.name == 'newsLetter') {
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: (event.target.checked) ? 'Yes' : '' }));
        } else if (event.target.name == 'email') {
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value.replace(' ', '') }));
        } else {
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
        }
    }

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            var res_data = await post(POST_SIGN_UP, form_inputs, {});
            if (res_data.status) {
                setUserSession(res_data.data.token, res_data.data);
                navigate('/');
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }
    return (<>
        <section className="form_page inner_page cms_page">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xxl-11 col-xl-11">
                        <div className="row align-items-center">
                            <div className="col-xxl-12 col-xl-12 col-lg-12">
                                <div className="form_wrapper form_cnt_lft simple_input">
                                    <h3 className="form_title">Please enter your details below</h3>
                                    <form action="" onSubmit={handleSubmit}>
                                        <div className="row g-4">
                                            <div className="col-xxl-6">
                                                <div className="form-group input-box mb-5">
                                                    <input type="text" name='userName' value={form_inputs.userName} onChange={handleInputChange} className="input form-control" placeholder="Your Name*" />
                                                    {simpleValidator.current.message('name', form_inputs.userName, 'required')}
                                                </div>
                                                <div className="form-group input-box mb-5">
                                                    <input type="text" name='email' value={form_inputs.email} onChange={handleInputChange} className="input form-control" placeholder="Email*" />
                                                    {simpleValidator.current.message('email', form_inputs.email, 'required|email')}
                                                </div>
                                                <div className="row mb-4">
                                                    <label className="form-label" for="">Your Mobile No.*</label>
                                                    <div className="form-group input-box">
                                                        <div className="d-flex align-items-end">
                                                            <div className=" select-box">
                                                                <select name="countryId" value={form_inputs.countryId} onChange={handleInputChange} className="input form-control small_input number_select">
                                                                    {Countries.length > 0 && Object.entries(Countries).map(([index, countri]) => {
                                                                        return (<option value={countri.countryId}>{countri.countryCode} +{countri.phonecode}</option>)
                                                                    })}
                                                                </select>
                                                            </div>
                                                            <input type="text" name='mobileNumber' value={form_inputs.mobileNumber} onChange={handleInputChange} className="input form-control" placeholder="Mobile*" />
                                                        </div>
                                                        {simpleValidator.current.message('mobile number', form_inputs.mobileNumber, 'required|numeric|min:8|max:12')}
                                                    </div>
                                                </div>
                                                <div className="form-group input-box mb-5">
                                                    <input type="password" name='password' value={form_inputs.password} onChange={handleInputChange} className="input form-control" placeholder="Password*" />
                                                    {simpleValidator.current.message('password', form_inputs.password, 'required')}
                                                </div>
                                                <div className="form-group input-box mb-5">
                                                    <input type="password" name='c_password' value={form_inputs.c_password} onChange={handleInputChange} className="input form-control" placeholder="Confirm Password*" />
                                                    {simpleValidator.current.message('confirm password', form_inputs.c_password, `required|in:${form_inputs.password}`, { messages: { in: `Passwords don't match.` } })}
                                                </div>
                                            </div>

                                            <div className="col-xxl-6">
                                                <div className="form-group input-box mb-5">
                                                    <input type="text" name='houseNumber' value={form_inputs.houseNumber} onChange={handleInputChange} className="input form-control" placeholder="Hourse Name and Number" />
                                                    {/* {simpleValidator.current.message('house number', form_inputs.houseNumber, 'required')} */}
                                                </div>
                                                <div className="form-group input-box mb-5">
                                                    <input type="text" name='street' value={form_inputs.street} onChange={handleInputChange} className="input form-control" placeholder="Street" />
                                                    {/* {simpleValidator.current.message('street', form_inputs.street, 'required')} */}
                                                </div>
                                                <div className="form-group input-box mb-5 pt-4">
                                                    <input type="text" name='postCode' value={form_inputs.postCode} onChange={handleInputChange} className="input form-control" placeholder="Postcode" />
                                                    {/* {simpleValidator.current.message('post code', form_inputs.postCode, 'required')} */}
                                                </div>
                                                <div className="form-group input-box mb-5">
                                                    <input type="text" name='city' value={form_inputs.city} onChange={handleInputChange} className="input form-control" placeholder="City" />
                                                    {/* {simpleValidator.current.message('city', form_inputs.city, 'required')} */}
                                                </div>
                                                <div className="form-group input-box checkbox">
                                                    <div className="form-group">
                                                        <input type="checkbox" name='newsLetter' value={form_inputs.newsLetter} onChange={handleInputChange} id="html" />
                                                        <label for="html">Subscribe to News</label>
                                                    </div>
                                                    <div className="form-group">
                                                        <input type="checkbox" name='tc' value={form_inputs.userName} onChange={handleInputChange} id="css" />
                                                        <label for="css">I have read and agreed with the <a href="#">Terms and Conditions</a>.</label>
                                                        {simpleValidator.current.message('terms and conditions.', form_inputs.tc, 'required')}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-xxl-12">
                                                <div className="submit_btn">
                                                    <button type="submit" disabled={ButtonDisabled} className='btn btn-default p-3 d-block w-100'>{ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>} Register </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </>
    );
}