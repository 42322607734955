
// auth
export const POST_LOGIN = "/auth/sign-in"
export const POST_FORGOT_PASSWORD = "/auth/forgot-password"
export const POST_SIGN_UP = "/auth/sign-up"
export const POST_RESET_PASSWORD = "/auth/reset-password"

export const GET_COUNTRIES_LIST = "/common/get-countries"
export const GET_AREA = "/common/get-area"


export const POST_BOOKING = "/booking/booking"
export const POST_GET_BOOKING = "/booking/get-booking"
export const POST_CANCEL_BOOKING = "/booking/cancel-booking"
export const POST_GET_PRICE = "/booking/get-price"
export const POST_GET_BOOKING_INFO = "/booking/get-booking-info"

export const POST_BOOKING_PDF = process.env.REACT_APP_API_URL+"pdf/booking?id="

