import React, { useRef, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import SimpleReactValidator from 'simple-react-validator';
import { toast } from 'react-toastify';
import { post } from '../../helpers/api_helper';
import { POST_FORGOT_PASSWORD, POST_RESET_PASSWORD } from '../../helpers/url_helper';
import { is_login, setUserSession } from '../../helpers/Helper';
import useMounted from '../../hooks/useMounted';


export default function Login(props) {
    const navigate = useNavigate();


    useMounted(() => {
        if(is_login()){
            navigate('/')
        }
    }, [])

    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [otp_screen, SetOTP_screen] = useState(false);
    const [form_inputs, setInputs] = useState({ email: '', password: '', c_password: '', otp: '' });

    const simpleValidator = useRef(new SimpleReactValidator());
    const OtpValidator = useRef(new SimpleReactValidator());
    const [, forceUpdate] = useState();

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            var res_data = await post(POST_FORGOT_PASSWORD, { 'email': form_inputs.email }, {});
            if (res_data.status) {
                SetOTP_screen(true);
                toast(res_data.message);
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    // form submit event
    const verifyOtp = async (event) => {
        event.preventDefault();
        const formValid = OtpValidator.current.allValid()
        if (!formValid) {
            OtpValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            var res_data = await post(POST_RESET_PASSWORD, form_inputs, {});
            if (res_data.status) {
                setUserSession(res_data.data.token, res_data.data);
                navigate('/')
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
    }

    return (<>
        <section className="form_page inner_page cms_page">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xxl-11 col-xl-11">
                        <div className="row g-4 align-items-center flex-row-reverse">
                            <div className="col-xxl-6 col-xl-6 col-lg-6">
                                <div className="form_cnt_rgt">
                                    <div className="title_1 text-left">
                                        <h2 className="title_main">
                                            First Time User to <span>Alpha Cars?</span>
                                        </h2>
                                        <p>{`Get Started Now. It’s Fast & Easy!`}</p>
                                        <Link to="/auth/register" className="btn btn-primary">Register</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-6 col-xl-6 col-lg-6">
                                <div className="form_wrapper form_cnt_lft simple_input">
                                    <h3 className="form_title">Forgot Password</h3>
                                    {!otp_screen &&
                                        <form action="" onSubmit={handleSubmit}>
                                            <div className="row">
                                                <div className="col-xxl-12">
                                                    <div className="form-group input-box">
                                                        <input type="text" name='email' value={form_inputs.email} onChange={handleInputChange} className="input form-control" placeholder="Email" />
                                                        {simpleValidator.current.message('email', form_inputs.email, 'required|email')}
                                                    </div>
                                                </div>
                                                <div className="col-xxl-12">
                                                    <div className="submit_btn">
                                                        <button type="submit" disabled={ButtonDisabled} className='btn btn-green p-3 d-block w-100'>{ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>}  Reset </button>
                                                    </div>
                                                </div>
                                                <div className="col-xxl-12 mt-4 text-center">
                                                    <Link to={`/auth/login`}>Back to Login?</Link>
                                                </div>
                                            </div>
                                        </form>
                                    }
                                    {otp_screen &&
                                        <form action="" onSubmit={verifyOtp}>
                                            <div className="row">
                                                <div className="col-xxl-12">
                                                    <div className="form-group input-box mb-4">
                                                        <input type="text" name='otp' maxLength="6" value={form_inputs.otp} onChange={handleInputChange} className="input form-control" placeholder="Enter your OTP" />
                                                        {OtpValidator.current.message('otp', form_inputs.otp, 'required|numeric|min:6')}
                                                    </div>
                                                    <div className="form-group input-box mb-4">
                                                        <input type="password" name='password' value={form_inputs.password} onChange={handleInputChange} className="input form-control" placeholder="Enter your New Password" />
                                                        {OtpValidator.current.message('password', form_inputs.password, 'required')}
                                                    </div>
                                                    <div className="form-group input-box mb-4">
                                                        <input type="password" name='c_password' value={form_inputs.c_password} onChange={handleInputChange} className="input form-control" placeholder="Enter your Confirm Password" />
                                                        {OtpValidator.current.message('confirm password', form_inputs.c_password, `required|in:${form_inputs.password}`, { messages: { in: `Passwords don't match.` } })}
                                                    </div>
                                                    <div className="submit_btn">
                                                        <button type="submit" disabled={ButtonDisabled} className='btn btn-green p-3 d-block w-100'>{ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>}  Change </button>
                                                    </div>
                                                    <div className="col-xxl-12 mt-4 text-center">
                                                        <Link to={`/auth/login`}>Back to Login?</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    }
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>

    </>
    );
}