const moment = require("moment");

const getToken = () => {
    return localStorage.getItem('token') || null;
}

const getUserDetail = () => {
    return JSON.parse(localStorage.getItem('user_detail'));
}

const getUserId = () => {
    return (localStorage.getItem('user_detail'))? JSON.parse(localStorage.getItem('user_detail'))['user_id'] : 0;
}

const getUserType = () => {
    if(localStorage.getItem('user_detail')){
        return JSON.parse(localStorage.getItem('user_detail'))['u_type'];
    }else{
        return 0;
    }
}

const removeUserSession = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user_detail');
}

const setUserSession = (token, user) => {
    localStorage.setItem('token', token);
    localStorage.setItem('user_detail', JSON.stringify(user));
}

const is_login = () => {
    if (localStorage.getItem('token')) {
        return true;
    } else {
        return false;
    }
}

const amountFormat = (amount) => {
    return '£' + parseFloat(amount).toFixed(2);
}


const SingalSelectValueArray = (option_array = [], value = '') => {
    return option_array.filter((item) => {
        return item.value === value;
    })
}

//multi_select_value_array
const MultiSelectValueArray = (option_array = [], valueListe = '') => {
    valueListe = valueListe.split(',');
    var select_option = [];
    if(valueListe.length){
        for(var value of valueListe){
            var option = option_array.filter((item) => {
                return item.value === value;
            })
            if(option){
                select_option.push(option[0]);
            }
        }
    }
    return select_option;
}

const arrayColumn = (column_array, column) => {
    const arrayColumn = (arr, n) => arr.map(x => x[n]);
    return arrayColumn(column_array, column);
}

const uct_local = (data_time,format ="DD/MM/YYYY HH:mm:ss") => {
    if(data_time){
        return moment.utc(data_time).local().format(format);
    }else{
        return '';
    }
}
export {uct_local, amountFormat, arrayColumn,getUserType,getToken,SingalSelectValueArray,MultiSelectValueArray, removeUserSession, setUserSession, is_login,getUserDetail, getUserId };