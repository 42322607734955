import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { post } from '../../helpers/api_helper';
import "react-datepicker/dist/react-datepicker.css";
import { POST_GET_BOOKING_INFO } from '../../helpers/url_helper';
import useMounted from "../../hooks/useMounted";
import { useSearchParams } from 'react-router-dom';

export default function BookingForm(props) {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    useMounted(() => {
        getBookingInfo();
    }, [])

    const [bookingDetail, setBookingDetail] = useState();
    const [reBookingDetail, setReBookingDetail] = useState();

    

    const getBookingInfo = async () => {
        var res_data = await post(POST_GET_BOOKING_INFO, {id : searchParams.get('id')});
        if (res_data.status) {
            setBookingDetail(res_data.data.booking_detail);
            setReBookingDetail(res_data.data.re_booking_detail);
        } else {
            toast.error(res_data.message);
            navigate('/');
        }
    }

 
    return (<>
        <div class="tab-content">
            <div class="tab-pane fade active show" id="home">
                <div class="tab_wrapper">
                   
                    {bookingDetail &&
                        <div className='row'>
                            <div className='col-sm-3'></div>
                            <div className='col-sm-6'>
                                <div className='text-center mb-5 '>
                                    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" viewBox="0,0,256,256" width="100px" height="100px"><g fill="#069852" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" ><g transform="scale(8.53333,8.53333)"><path d="M15,3c-6.627,0 -12,5.373 -12,12c0,6.627 5.373,12 12,12c6.627,0 12,-5.373 12,-12c0,-6.627 -5.373,-12 -12,-12zM21.707,12.707l-7.56,7.56c-0.188,0.188 -0.442,0.293 -0.707,0.293c-0.265,0 -0.52,-0.105 -0.707,-0.293l-3.453,-3.453c-0.391,-0.391 -0.391,-1.023 0,-1.414c0.391,-0.391 1.023,-0.391 1.414,0l2.746,2.746l6.853,-6.853c0.391,-0.391 1.023,-0.391 1.414,0c0.391,0.391 0.391,1.023 0,1.414z"></path></g></g></svg>
                                    <h2 className='mt-2 mb-4'>Success</h2>
                                    <p className='mb-0'><small> Thank you for booking with Alpha Cars - Executive & Airport Transfer.</small></p>
                                    <p><small>We will process this booking request & you will receive an email confirmation shortly.</small></p>
                                </div>

                                <div className='mb-4'>
                                    <h3>Booking Details</h3>
                                    <div className='row'>
                                        <div className='col-sm-4'><b>Booking Id:</b></div>
                                        <div className='col-sm-8'>{bookingDetail.bookingNumber}</div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-4'><b>Pickup Time:</b></div>
                                        <div className='col-sm-8'>{bookingDetail.pickupTime}</div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-4'><b>Payment Method:</b></div>
                                        <div className='col-sm-8'>{bookingDetail.paymentMethod}</div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-4'><b>Price:</b></div>
                                        <div className='col-sm-8'>£{bookingDetail.price}</div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-4'><b>Pickup:</b></div>
                                        <div className='col-sm-8'>{bookingDetail.pickup_point} {bookingDetail.pickupAddress} </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-4'><b>Dropoff:</b></div>
                                        <div className='col-sm-8'>{bookingDetail.dropoff_point} {bookingDetail.dropoffAddress}</div>
                                    </div>
                                </div>

                                {reBookingDetail && <div className='mb-4'>
                                    <h3>Return Booking Details</h3>
                                    <div className='row'>
                                        <div className='col-sm-4'><b>Booking Id:</b></div>
                                        <div className='col-sm-8'>{reBookingDetail.bookingNumber}</div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-4'><b>Pickup Time:</b></div>
                                        <div className='col-sm-8'>{reBookingDetail.pickupTime}</div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-4'><b>Payment Method:</b></div>
                                        <div className='col-sm-8'>{reBookingDetail.paymentMethod}</div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-4'><b>Price:</b></div>
                                        <div className='col-sm-8'>£{reBookingDetail.price}</div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-4'><b>Pickup:</b></div>
                                        <div className='col-sm-8'>{reBookingDetail.pickup_point} {reBookingDetail.pickupAddress}</div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-4'><b>Dropoff:</b></div>
                                        <div className='col-sm-8'>{reBookingDetail.dropoff_point} {reBookingDetail.dropoffAddress}</div>
                                    </div>
                                </div>}
                                <div>
                                    <Link to="/booking" className='btn btn-green w-100'>Go to Booking</Link>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>

    </>
    );
}