import React, { useRef, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import SimpleReactValidator from 'simple-react-validator';
import { toast } from 'react-toastify';
import { post } from '../../helpers/api_helper';
import { POST_LOGIN } from '../../helpers/url_helper';
import { setUserSession, is_login } from '../../helpers/Helper';
import useMounted from '../../hooks/useMounted';


export default function Login(props) {
    const navigate = useNavigate();

    useMounted(() => {
        if(is_login()){
            navigate('/')
        }
    }, [])

    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [form_inputs, setInputs] = useState({
        email: '',
        password: '',
    });

    const simpleValidator = useRef(new SimpleReactValidator());
    const [, forceUpdate] = useState();

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
    }

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            var res_data = await post(POST_LOGIN, form_inputs, {});
            if (res_data.status) {
                setUserSession(res_data.data.token, res_data.data);
                navigate('/');
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }
    return (<>
        <section className="form_page inner_page cms_page">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xxl-11 col-xl-11">
                        <div className="row g-4 align-items-center flex-row-reverse">
                            <div className="col-xxl-6 col-xl-6 col-lg-6">
                                <div className="form_cnt_rgt">
                                    <div className="title_1 text-left">
                                        <h2 className="title_main">
                                            First Time User to <span>Alpha Cars?</span>
                                        </h2>
                                        <p>{`Get Started Now. It’s Fast & Easy!`}</p>
                                        <Link to="/auth/register" className="btn btn-primary">Register</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-6 col-xl-6 col-lg-6">
                                <div className="form_wrapper form_cnt_lft simple_input">
                                    <h3 className="form_title">Sign in</h3>
                                    <form action="" onSubmit={handleSubmit}>
                                        <div className="row">
                                            <div className="col-xxl-12">
                                                <div className="form-group input-box">
                                                    <input type="text" name='email' value={form_inputs.email} onChange={handleInputChange} className="input form-control" placeholder="Email" />
                                                    {simpleValidator.current.message('email', form_inputs.email, 'required|email')}
                                                </div>
                                            </div>
                                            <div className="col-xxl-12">
                                                <div className="form-group input-box">
                                                    <input type="password" name='password' value={form_inputs.password} onChange={handleInputChange} className="input form-control password" placeholder="Password" />
                                                    {simpleValidator.current.message('password', form_inputs.password, 'required')}
                                                </div>
                                            </div>
                                            <div className="col-xxl-12">
                                                <Link to={`/auth/forgot-password`} className='pull-right'>Forgot Password?</Link>
                                            </div>
                                            <div className="col-xxl-12">
                                                <div className="submit_btn">
                                                    <button type="submit" disabled={ButtonDisabled} className='btn btn-green p-3 d-block w-100'>{ButtonDisabled && <i className="fa fa-spinner me-2 fa-spin"></i>}  Sign In </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>

    </>
    );
}