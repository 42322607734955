
import { Outlet, useNavigate } from "react-router-dom"
import Header from "../component/Header"
import Footer from "../component/Footer"
import UserMenu from "../component/UserMenu"
import useMounted from "../hooks/useMounted";
import { is_login } from '../helpers/Helper';


export default function Layout() {
    const navigate = useNavigate();

    useMounted(() => {
        if (!is_login()) {
            navigate('/auth/login')
        }
    }, [])

    return (
        <>
            <Header />
            <section class="inner_page cms_page">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-xxl-11 col-xl-11">
                            <div class="row align-items-center">
                                <div class="col-xxl-12 col-xl-12 col-lg-12">
                                    <UserMenu />
                                    <Outlet />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}