import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
// import Swiper from 'swiper'; // Import Swiper (Method 1)
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

export default function Header(props) {
    const [swiperInstance, setSwiperInstance] = useState(null);



    return (<>
        <footer className="footer">
            <div className="top_footer">
                <div className="container">
                    <div className="d-flex align-items-center">
                        <div className="footer_logo">
                            <img src="/assets/images/footer_logo.svg" alt="" />
                        </div>
                        <div className="footer_rgt">
                            <h2>Yes! You Can Pay By Cash or Card in Our Cars...</h2>
                            <p>No transaction fees for card payments.</p>
                            <ul>
                                <li><img src="/assets/images/ficon1.svg" alt="" /></li>
                                <li><img src="/assets/images/paymentimg.svg" alt="" /></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bottom_footer">
                <div className="container">
                    <div className="d-flex align-items-center justify-content-between">
                        <div>
                            <p>© 2024 Alpha Cars. All Rights Reserved.</p>
                        </div>
                        <div>
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <li><Link to="/">Terms</Link></li>
                                <li><Link to="/">Privacy Policy</Link></li>
                                <li><Link to="/">Contact</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        <div className="adminButtons">
            <div className="textslider">
                <div className="swiper swiper-container-text swiper-fade swiper-initialized swiper-horizontal swiper-watch-progress swiper-backface-hidden">
                    <Swiper className="swiper-wrapper mySwiper"
                        id="swiper-wrapper"
                        slidesPerView={1}
                        spaceBetween={3}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        modules={[Autoplay]}
                    >
                        <SwiperSlide>
                            <div className="swiper-slide" style={{ "width": "173px"}} role="group" aria-label="1 / 3"><span>Support/Booking</span></div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="swiper-slide swiper-slide-prev" style={{ "width": "173px" }} role="group" aria-label="2 / 3"><span>Executive &amp; Airport Transfer</span></div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="swiper-slide swiper-slide-visible" style={{ "width": "173px" }} role="group" aria-label="3 / 3"><span>01892 722 722</span></div>
                        </SwiperSlide>
                    </Swiper>
                    {/* <div className="swiper-wrapper mySwiper" id="swiper-wrapper" aria-live="off" style={{"transitionDuration": "0ms"}}>
                        <div className="swiper-slide slide1" style={{"width": "173px", "opacity": "0", "transform": "translate3d(0px, 0px, 0px)", "transitionDuration": "0ms"}} role="group" aria-label="1 / 3"><span>Support/Booking</span></div>
                        <div className="swiper-slide slide2 swiper-slide-prev" style={{"width": "173px", "opacity": "0", "transform": "translate3d(-173px, 0px, 0px)", "transitionDuration": "0ms"}} role="group" aria-label="2 / 3"><span>Executive &amp; Airport Transfer</span></div>
                        <div className="swiper-slide slide3 swiper-slide-visible swiper-slide-active" style={{"width": "173px", "opacity": "1", "transform": "translate3d(-346px, 0px, 0px)", "transitionDuration": "0ms"}} role="group" aria-label="3 / 3"><span>01892 722 722</span></div>
                    </div> */}
                    <span className="swiper-notification" aria-live="assertive" aria-atomic="true"></span></div>
            </div>
            <a href="https://api.whatsapp.com/send?phone=447887024069" target="_blank" title="Edit User"><img src="/assets/images/whatsapp.svg" alt="" /></a>
        </div>

    </>);
}